import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
const isBrowser = typeof window !== `undefined`

class DocumentIndex extends Component {

  constructor(props) {
    super(props)
    this.state = {
      documents: []
    }
  }

  componentDidMount(){
    if(isBrowser){
      fetch('https://api.forms-surfaces.net/email/document-index/1538')
      .then(response => response.json())
      .then(data => {
        this.setState({
        documents: data
      })});
    }
  }

  render(){
    return(
      <Layout>
        <SEO title="Document Index" />
        {this.state.documents.map(document => {
          let documents = document.documents.map(item => {
            return (<div className="row">
            <div className="col-sm-6">
            {item.title}
            </div>
            <div className="col-sm-6">
            {item.link}
            </div>
            </div>)
          })
          return (<div style={{'paddingBottom': '2rem'}}><div className="row"><div className="col-sm-12" style={{'backgroundColor': '#888888', 'color': '#fff'}}><a style={{'color': '#fff'}} href={document.link} target="_blank" rel="noreferrer">{document.title}</a></div></div>{documents}</div>)
        })}
      </Layout>
    )
  }
}

export default DocumentIndex
